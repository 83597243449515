<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Offer
            <v-spacer></v-spacer>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
            </transition>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="gradeId"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm4>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  :disabled="!gradeId"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :items="['All', 'With Offer']"
                  class="pa-0"
                  label="Type"
                  v-model="type"
                  :disabled="!section"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>

          <v-container
            v-if="
              doesHaveOldbatch === true &&
              studentWithFeeOffers.length < 1 &&
              batch.is_current_batch &&
              form.items.data.length > 0 &&
              !form.loading
            "
          >
            <alert-message type="error" title="No Fee Offers has been set">
              <template v-slot:message>
                No Fee Offer record defined for the selected grade & section.
                Would you like to migrate the fee offer records from old batch?
                <br />
                <a href="" @click.prevent="fetchCustomizedHeadFromOldBatch"
                  >Click Here</a
                >
              </template>
            </alert-message>
            <br />
          </v-container>

          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            sort-by="id"
            :expanded.sync="expanded"
            show-expand
            item-key="id"
          >
            <template
              v-slot:item.data-table-expand="{ expand, isExpanded, item }"
            >
              <span v-if="item.fee_offers.length > 0">
                <v-icon
                  style="cursor: pointer"
                  color="error"
                  v-if="isExpanded"
                  @click="expand(!isExpanded)"
                >
                  indeterminate_check_box
                </v-icon>
                <v-icon
                  style="cursor: pointer"
                  color="primary"
                  v-else
                  @click="expand(!isExpanded)"
                >
                  add_circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon style="cursor: no-drop"> add_circle </v-icon>
              </span>
            </template>
            <template v-slot:item.fee_offer="{ item }">
              {{ item.fee_offers.length }}
              <v-icon small color="success" v-if="item.fee_offers.length > 0"
                >check_circle
              </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <add-button
                permission="fee-offer-read"
                @action="addFeeOffer(item)"
                >Add
              </add-button>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card
                  class="elevation-1"
                  v-if="item.fee_offers.length > 0"
                  style="border-radius: 0; width: 100%"
                >
                  <table class="table" style="width: 100%">
                    <thead>
                      <tr>
                        <th class="text-xs-left"><strong>Fee Head</strong></th>
                        <th class="text-xs-left">
                          <strong>Fee Head Amount</strong>
                        </th>
                        <th class="text-xs-left"><strong>Discount </strong></th>
                        <th class="text-xs-left">
                          <strong>Scholarship </strong>
                        </th>
                        <th class="text-xs-left">
                          <strong>Remarks </strong>
                        </th>
                        <th class="text-right"><strong>Action</strong></th>
                      </tr>
                      <tr
                        v-for="(x, i) in item.fee_offers"
                        :key="i"
                        style="height: 0px"
                      >
                        <td class="text-xs-left">{{ x.title }}</td>
                        <td class="text-xs-left">{{ x.amount.currency() }}</td>
                        <td class="text-xs-left">
                          {{ x.discount_percentage }}%
                          <p>({{ x.discount_amount.currency() }})</p>
                        </td>
                        <td class="text-xs-left">
                          {{ x.scholar_percentage }}%
                          <p>({{ x.scholar_amount.currency() }})</p>
                        </td>
                        <td class="text-xs-left">
                          {{ x.remarks || "-" }}
                        </td>
                        <td class="text-right">
                          <edit-button
                            permission="fee-offer-update"
                            @agree="editData(x, item.name, item.enroll_id)"
                          />
                          <delete-button
                            permission="fee-delete"
                            @agree="form.delete(x.id)"
                          />
                        </td>
                      </tr>
                    </thead>
                  </table>
                </v-card>
                <v-card v-else>
                  <v-card-text> No fee Offer </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title"
              >Add/Update <small>({{ student_name }})</small></span
            ><v-spacer></v-spacer
            ><span><small>*Indicates required field</small></span>
          </v-card-title>
          <v-card-text class="pb-1">
            <br />
            <v-layout
              row
              wrap
              pa-3
              v-for="(row, key) in discountScholarList"
              :key="key"
            >
              <v-flex xs6 sm6>
                <v-select
                  @change="reset(row), getAmount(row)"
                  :items="
                    row.fee_id
                      ? [
                          fees.find((item) => item.value === row.fee_id),

                          ...filteredFees,
                        ]
                      : filteredFees
                  "
                  required
                  class="pa-0 pl-2"
                  label="Select Title*"
                  v-model="row.fee_id"
                  name="fee_id"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Title Field is required']"
                />
              </v-flex>

              <v-flex xs6 sm6>
                <v-text-field
                  autocomplete="off"
                  label="Amount*"
                  class="pa-0 pl-2"
                  v-model="row.amount"
                  name="discount"
                  :error-messages="form.errors.get('amount')"
                  type="number"
                  :disabled="true"
                  outlined
                  dense
                />
              </v-flex>

              <v-flex xs3 sm3>
                <v-text-field
                  autocomplete="off"
                  @keyup="discountPerChange(row)"
                  label="Discount(%)*"
                  class="pa-0 pl-2"
                  v-model="row.discount_percentage"
                  name="discount_percentage"
                  :disabled="
                    (row.scholar_percentage.length > 0 &&
                      parseInt(row.scholar_percentage) != 0) ||
                    !row.fee_id
                  "
                  :error-messages="form.errors.get('discount_percentage')"
                  type="number"
                  outlined
                  dense
                />
              </v-flex>

              <v-flex xs3 sm3>
                <v-text-field
                  autocomplete="off"
                  label="Discount Amount"
                  @keyup="discountAmountChange(row)"
                  class="pa-0 pl-2"
                  v-model="row.discount_amount"
                  name="discount_amount"
                  :disabled="
                    (row.scholar_percentage.length > 0 &&
                      parseInt(row.scholar_percentage) != 0) ||
                    !row.fee_id
                  "
                  :error-messages="form.errors.get('discount_amount')"
                  type="number"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-text-field
                  autocomplete="off"
                  :disabled="
                    (row.discount_percentage.length > 0 &&
                      parseInt(row.discount_percentage) != 0) ||
                    !row.fee_id
                  "
                  @keyup="scholarPerChange(row)"
                  label="Scholar(%) *"
                  class="pa-0 pl-2"
                  v-model="row.scholar_percentage"
                  name="scholar_percentage"
                  :error-messages="form.errors.get('scholar_percentage')"
                  type="number"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-text-field
                  autocomplete="off"
                  :disabled="
                    (row.discount_percentage.length > 0 &&
                      parseInt(row.discount_percentage) != 0) ||
                    !row.fee_id
                  "
                  label="Scholar Amount"
                  @keyup="scholarAmountChange(row)"
                  class="pa-0 pl-2"
                  v-model="row.scholar_amount"
                  name="scholar_percentage"
                  :error-messages="form.errors.get('scholar_amount')"
                  type="number"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs12 sm12>
                <v-textarea
                  label="Remarks (optional)"
                  rows="1"
                  placeholder="Mention why this scholarship/discount is being given."
                  class="pa-0 pl-2"
                  v-model="row.remarks"
                  outlined
                >
                </v-textarea>
              </v-flex>

              <v-flex xs1 sm1>
                <v-btn
                  v-if="!(discountScholarList.length < 2)"
                  class="ml-1 mt-1 mb-1"
                  outlined
                  x-small
                  color="error"
                  @click="removeElement(row)"
                >
                  <v-icon x-small>clear</v-icon>
                  <strong class="ml-1">Delete</strong>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info"
              outlined
              @click="addNewRow"
              v-if="!updateData"
              >Add More</v-btn
            >
            <v-btn color="warning" outlined @click="closeDialog()">Close</v-btn>
            <v-btn color="success" outlined @click="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-dialog v-model="migrateDialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Fee Offer Record From {{ oldBatch.name }}</span>
          <v-spacer></v-spacer>
          <a href="" @click.prevent="migrateDialog = false"
            ><v-icon color="white">close</v-icon></a
          >
        </v-card-title>

        <v-card-text class="pb-1">
          <v-flex>
            <br />
            <v-text-field
              disabled
              outlined
              dense
              autocomplete="off"
              label="Migrate From Batch"
              required
              :value="oldBatch.name"
              name="fare_amount"
            />
            <v-grade-field
              @selectedGrade="selectedFromGrade"
              v-model="oldGradeId"
              :inside="true"
              label="Migrate From Grade"
              :batch-id="oldBatch.id"
            />
          </v-flex>
          <br />
          <div style="display: flex">
            <div class="data-represent" style="margin-top: 8px">
              <div>
                <span class="d-success"></span> &nbsp;
                <small> {{ oldCustomizedList.length }} Total Students</small>
              </div>
              <div>
                <span class="d-primary"></span> &nbsp;
                <small
                  ><strong>{{ "" }}</strong> Total Amout</small
                >
              </div>
            </div>
          </div>

          <v-data-table
            :headers="oldRecordHeader"
            :hide-default-footer="true"
            :items="oldCustomizedList"
            :footer-props="footerProps"
            sort-by="roll"
            :options.sync="pagination"
            :server-items-length="oldCustomizedList.length"
          >
            <template v-slot:item="{ index, item }">
              <tr :key="index">
                <td>
                  {{ item.name }}
                </td>
                <td class="text-left">
                  <span
                    class="d-block mt-2 mb-2"
                    v-for="(fee_offer, i) in item.fee_offers"
                    :key="i"
                  >
                    <v-chip class="mr-1" small label>
                      {{ fee_offer.title }} &nbsp;
                      <span v-if="fee_offer.scholar_percentage"
                        >(SCH {{ fee_offer.scholar_percentage }}%)
                      </span>
                      <span v-else-if="fee_offer.discount_percentage"
                        >(DIS {{ fee_offer.discount_percentage }}%)
                      </span>
                    </v-chip>
                  </span>
                </td>
                <td class="text-right">
                  {{
                    item.fee_offers
                      .map(
                        (item) =>
                          parseFloat(item.scholar_amount * 1) +
                          parseFloat(item.discount_amount * 1)
                      )
                      .reduce((pre, cur) => pre + cur, 0)
                      .currency()
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <br />
        <v-container>
          <alert-message
            v-if="oldCustomizedList.length > 0"
            :type="'warning'"
            extraSmall
            :title="'Amount will be replaced by new fee charge settings'"
            :message="`Amount from previous batch record will be overridden by the new fee charge setup.`"
          ></alert-message>
        </v-container>
        <br />
        <v-card-actions>
          <v-btn
            :disabled="oldCustomizedList.length < 1"
            color="success"
            block
            large
            outlined
            @click="migrateCustomizedHead"
            >Migrate to {{ batch.name }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { hasOldBatch } from "@/library";

export default {
  mixins: [Mixins],
  data: () => ({
    studentWithFeeOffers: [],
    migrateDialog: false,
    oldBatch: {},
    oldGradeId: "",
    type: "All",
    oldCustomizedList: [],
    valid: true,
    lazy: false,
    gradeLoading: false,
    studentLoading: false,
    discountScholarList: [
      {
        fee_id: "",
        section_id: "",
        grade_id: "",
        enroll_id: "",
        discount_percentage: 0,
        discount_amount: 0,
        scholar_percentage: 0,
        scholar_amount: 0,
        remarks: "",
        fee_head_id: "",
      },
    ],
    form: new Form(
      {
        fee_id: "",
        section_id: "",
        grade_id: "",
        enroll_id: "",
        discount_percentage: 0,
        discount_amount: 0,
        scholar_percentage: 0,
        scholar_amount: 0,
        remarks: "",
      },

      "/api/fee-offer"
    ),
    search: null,
    headers: [
      { text: "Roll", align: "left", value: "roll", sortable: false },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 300,
      },
      { text: "Fee Offer", align: "left", value: "fee_offer", sortable: false },
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
        width: 200,
      },
    ],
    oldRecordHeader: [
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
      },
      { text: "Fee Offer", align: "left", value: "fee_offer", sortable: false },
      {
        text: "Amount",
        align: "right",
        value: "type",
        sortable: false,
        width: 180,
      },
    ],
    day: "all",
    sections: [],
    grades: [],
    gradeId: "",
    section: "",
    subjects: [],
    teachers: [{ text: 1, value: 1 }],
    student: "",
    students: [],
    student_name: "",
    enroll_id: null,
    fees: [],
    expanded: [],
    updateData: false,
    expand: false,
    dialog: false,
  }),

  computed: {
    ...mapState(["batch", "batches", "grade"]),
    doesHaveOldbatch() {
      return hasOldBatch(this.batches);
    },
    filteredFees() {
      const selectedFeeHeads = this.discountScholarList
        .map((item) => item.fee_head_id)
        .filter(Boolean);

      const filteredFeeHeads = [];
      this.fees.map((item) => {
        if (!selectedFeeHeads.includes(item.fee_head_id)) {
          filteredFeeHeads.push(item);
        }
      });
      return filteredFeeHeads;
    },
  },

  mounted() {
    this.getGrades();
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    gradeId: function () {
      this.section = "";
      this.form.items.data = [];
      this.getSections();
      this.getFeeData();
    },
    section: function () {
      this.get();
    },
    type: function () {
      this.get();
    },
  },

  methods: {
    migrateCustomizedHead() {
      this.$rest
        .post("/api/fee-offer/migrate", {
          grade_id: this.gradeId,
          batch_id: this.batch.id,
          records: this.oldCustomizedList,
        })
        .then((res) => {
          this.migrateDialog = false;
          this.get();
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    selectedFromGrade(grade) {
      let query = this.queryString(
        `grade=${grade.id}&rowsPerPage=1000&batchId=${this.oldBatch.id}&forMigration=true`
      );
      this.$rest.get(`/api/fee-offer${query}`).then((response) => {
        this.oldCustomizedList = response.data.data;
      });
    },
    fetchCustomizedHeadFromOldBatch() {
      let finalBatches = this.batches.filter((item) => {
        return item.is_hs === this.batch.is_hs && item.id !== this.batch.id;
      });

      let oldBatch = finalBatches[finalBatches.length - 1];

      if (oldBatch && !oldBatch.is_current_batch) {
        this.oldCustomizedList = [];
        this.oldBatch = oldBatch;
        this.migrateDialog = true;
        this.oldGradeId = "";
      }else {
        this.$events.fire('notification', {
            message: 'No old batch detected',
            status: 'error',
        });
      }
    },
    validate() {
      try {
        this.discountScholarList.map((item) => {
          if (
            item.amount &&
            item.fee_id &&
            item.fee_head_id &&
            (item.discount_percentage || item.scholar_percentage) &&
            (item.discount_amount || item.scholar_amount)
          ) {
            return true;
          } else {
            throw Error("Invalid data");
          }
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    get(params) {
      if (this.section && this.day) {
        let extraParams = "sectionId=" + this.section + "&rowsPerPage=200";
        if (this.type === "With Offer") extraParams += "&onlyFeeOffers=true";
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.studentWithFeeOffers = data.data.filter(
            (item) => item.fee_offers.length > 0
          );
          this.pagination.totalItems = data.meta.total;
        });
      }
    },

    save() {
      if (!this.enroll_id) return false;

      if (!this.validate()) {
        this.$events.fire("notification", {
          message: "Invalid value given",
          status: "error",
        });
        return false;
      }
      this.$rest
        .post("/api/fee-offer", {
          feeOfferBulk: this.discountScholarList.map((item) => ({
            ...item,
            enroll_id: this.enroll_id,
          })),
        })
        .then((res) => {
          this.dialog = false;
          this.get();
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {
          this.discountScholarList = [];
          this.discountScholarList.push({
            fee_id: "",
            section_id: "",
            grade_id: "",
            enroll_id: "",
            discount_percentage: 0,
            discount_amount: 0,
            scholar_percentage: 0,
            scholar_amount: 0,
            remarks: "",
            fee_head_id: "",
          });
        });
    },

    reset(row) {
      row.discount_percentage = 0;
      row.discount_amount = 0;
      row.scholar_percentage = 0;
      row.scholar_amount = 0;
      row.remarks = "";
    },

    getGrades() {
      this.gradeLoading = true;
      this.section = "";
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&descending=false&slim=true&rowsPerPage=1000"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

    getSections() {
      this.student = "";
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.gradeId)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },

    getFeeData() {
      this.$rest
        .get(
          "/api/fee?rowsPerPage=" +
            1000 +
            "&batchId=" +
            this.batch.id +
            "&gradeId=" +
            this.gradeId
        )
        .then(({ data }) => {
          this.fees = data.data.map((item) => {
            return {
              value: item.id,
              fee_head_id: item.fee_head_id,
              text: item.fee_head.title,
              amount: item.amount,
            };
          });
        });
    },
    getAmount(row) {
      let filterData = this.fees.find((item) => item.value === row.fee_id);
      row.amount = filterData.amount;
      row.fee_head_id = filterData.fee_head_id;

      if (this.updateData) {
        this.discountScholarList[0].amount = filterData.amount;
        this.discountScholarList[0].fee_head_id = filterData.fee_head_id;
      }
    },
    addFeeOffer(data) {
      this.updateData = false;
      this.student_name = data.name;
      this.enroll_id = data.enroll_id;
      this.dialog = true;
    },
    editData(data, name, enroll_id) {
      this.student_name = name;
      this.enroll_id = enroll_id;
      this.discountScholarList[0].fee_id = data.fee_id;
      this.discountScholarList[0].id = data.id;
      this.discountScholarList[0].discount_percentage =
        data.discount_percentage;
      this.discountScholarList[0].discount_amount = data.discount_amount;
      this.discountScholarList[0].scholar_percentage = data.scholar_percentage;
      this.discountScholarList[0].scholar_amount = data.scholar_amount;
      this.discountScholarList[0].remarks = data.remarks;
      this.updateData = true;
      this.getAmount(data);
      this.dialog = true;
    },
    discountPerChange(row) {
      if (row.discount_percentage > 100) row.discount_percentage = 100;

      if (parseFloat(row.discount_percentage) > 0) {
        row.scholar_percentage = 0;
        row.scholar_amount = 0;
      }
      row.discount_amount = parseFloat(
        (row.amount * row.discount_percentage) / 100
      ).toFixed();
    },
    discountAmountChange(row) {
      if (row.discount_amount > row.amount) row.discount_amount = row.amount;

      if (parseFloat(row.discount_amount) > 0) {
        row.scholar_percentage = 0;
        row.scholar_amount = 0;
      }
      row.discount_percentage = parseFloat(
        (row.discount_amount / row.amount) * 100
      ).toFixed();
    },
    scholarPerChange(row) {
      if (row.scholar_percentage > 100) row.scholar_percentage = 100;
      if (parseFloat(row.scholar_percentage) > 0) {
        row.discount_percentage = 0;
        row.discount_amount = 0;
      }
      row.scholar_amount = parseFloat(
        (row.amount * row.scholar_percentage) / 100
      ).toFixed();
    },
    scholarAmountChange(row) {
      if (parseFloat(row.scholar_amount) > parseFloat(row.amount))
        row.scholar_amount = parseFloat(row.amount);
      if (parseFloat(row.scholar_amount) > 0) {
        row.discount_percentage = 0;
        row.discount_amount = 0;
      }
      row.scholar_percentage = parseFloat(
        (row.scholar_amount / row.amount) * 100
      ).toFixed();
    },
    closeDialog() {
      this.updateData = false;
      this.student_name = "";
      this.enroll_id = "";
      this.discountScholarList = [];
      this.discountScholarList.push({
        fee_id: "",
        section_id: "",
        grade_id: "",
        enroll_id: "",
        discount_percentage: 0,
        discount_amount: 0,
        scholar_percentage: 0,
        scholar_amount: 0,
        remarks: "",
        fee_head_id: "",
      });
      this.dialog = false;
    },
    addNewRow() {
      this.discountScholarList.push({
        fee_id: "",
        section_id: "",
        grade_id: "",
        enroll_id: "",
        discount_percentage: 0,
        discount_amount: 0,
        scholar_percentage: 0,
        scholar_amount: 0,
        remarks: "",
        fee_head_id: "",
      });
    },
    removeElement(row) {
      var index = this.discountScholarList.indexOf(row);
      this.discountScholarList.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
tr.highlight {
  cursor: pointer !important;
}
</style>
